const rvrConfigType = {
  GET_RVR_DEVICE_LIST: "GET_RVR_DEVICE_LIST",
  GET_RVR_DEVICE_LIST_COMMIT: "GET_RVR_DEVICE_LIST_COMMIT",
  GET_RVR_DEVICE_LIST_ROLLBACK: "GET_RVR_DEVICE_LIST_ROLLBACK",

  RVR_DEVICE_OPTION: "RVR_DEVICE_OPTION",
  RVR_TCS_OPTION: "RVR_TCS_OPTION",
  // set user selection
  SET_USER_SELECTION: "RVR_SET_USER_SELECTION",

  // tcs main
  POST_RVR_TCS_RESULT: "POST_RVR_TCS_RESULT",
  POST_RVR_TCS_RESULT_COMMIT: "POST_RVR_TCS_RESULT_COMMIT",
  POST_RVR_TCS_RESULT_ROLLBACK: "POST_RVR_TCS_RESULT_ROLLBACK",

  INIT_LOADING_STATUS: "INIT_LOADING_STATUS",
};

export default rvrConfigType;

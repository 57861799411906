const batteryConfigType = {
    GET_BATTERY_DEVICE_LIST: "GET_BATTERY_DEVICE_LIST",
    GET_BATTERY_DEVICE_LIST_COMMIT: "GET_BATTERY_DEVICE_LIST_COMMIT",
    GET_BATTERY_DEVICE_LIST_ROLLBACK: "GET_BATTERY_DEVICE_LIST_ROLLBACK",
  
    // set user selection
    SET_USER_SELECTION: "BATTERY_SET_USER_SELECTION",
  
    // tcs main
    POST_BATTERY_TCS_RESULT: "POST_BATTERY_TCS_RESULT",
    POST_BATTERY_TCS_RESULT_COMMIT: "POST_BATTERY_TCS_RESULT_COMMIT",
    POST_BATTERY_TCS_RESULT_ROLLBACK: "POST_BATTERY_TCS_RESULT_ROLLBACK",
  
    // View template list
    GET_BATTERY_VIEW_TEMPLATE_LIST: "GET_BATTERY_VIEW_TEMPLATE_LIST",
    GET_BATTERY_VIEW_TEMPLATE_COMMIT: "GET_BATTERY_VIEW_TEMPLATE_COMMIT",
    GET_BATTERY_VIEW_TEMPLATE_ROLLBACK: "GET_BATTERY_VIEW_TEMPLATE_ROLLBACK",
  
    INIT_LOADING_STATUS: "INIT_LOADING_STATUS",
  };
  
  export default batteryConfigType;
  
import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  Button,
  FormField,
  Input,
  Modal,
  ExpandableSection
} from "@amzn/awsui-components-react/polaris";
import { setUserSelectDict } from "../../../../redux/actions/rvr-config-action";
import { 
  DEFAULT_TECHNOLOGY,
  RANGE_TYPE_OUTDOOR, 
  RANGE_TYPE_INDOOR, 
  RANGE_CONSTANT_ERROR,
  RANGE_CONSTANT } from "../../../../config/constants";
import { findTitle, findRangeConstants } from "../../../visualization/rvr/rvr-range-chart/range-calculator-utils";

interface StateProps {
  rvrConfigReducer: any;
}

// declare prop check
type Props = {
  filterObject: Object;
  dispatch: Dispatch<any>;
  onDismiss: () => void; // onDismiss event
  onClose: () => void; // onClose event
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({
  showModal: false,
});

class RvRRangeModal extends Component<Props> {
  readonly state = {};

  componentDidMount() {
    const {selectedData} =  this.props.rvrConfigReducer;

    Object.keys(selectedData).map((eachKey, index) => (
        this.setState({
          [eachKey] : {
            ...selectedData[eachKey]["rangeCalculator"],
            txGainError: "",
            rxGainError: "",
            desenseError: "",
            shadowFadingError: "",
            frequencyError: "",
            dutToDutVariationError: "",
            belMedianError: "",
            belSigmaError: "",
            sfSigmaError: ""
        }
        })
      ))   
  }
  componentDidUpdate(prevProps, prevState) {
    let current_technology = []
    let prev_technology = []
    const {selectedData} = this.props.rvrConfigReducer;
    Object.keys(selectedData).forEach((eachKey) => {
      current_technology.push(selectedData[eachKey].technology)
    })
    Object.keys(prevProps.rvrConfigReducer.selectedData).forEach((eachKey) => {
      prev_technology.push(prevProps.rvrConfigReducer.selectedData[eachKey].technology)
    })
    if(current_technology.every((value, index) => value !== prev_technology[index])){
      this._onReset()
    }
  }
  handleSubmit = () => {
    // clean up response error from last submit
    const { state } = this
    this._cleanup();
    let testCases = []
    Object.keys(state).forEach((eachKey) => {
      testCases.push({
        txGain: { value: state[eachKey].txGain, error: RANGE_CONSTANT_ERROR["txGain"]},
        rxGain: { value: state[eachKey].rxGain, error: RANGE_CONSTANT_ERROR["rxGain"]},
        desense: { value: state[eachKey].desense, error: RANGE_CONSTANT_ERROR["desense"]},
        shadowFading: { value: state[eachKey].shadowFading,error: RANGE_CONSTANT_ERROR["shadowFading"]},
        frequency: { value: state[eachKey].frequency, error: RANGE_CONSTANT_ERROR["frequency"]},
        dutToDutVariation: { value: state[eachKey].dutToDutVariation, error: RANGE_CONSTANT_ERROR["dutToDutVariation"]},
        belMedian: { value: state[eachKey].belMedian, error: RANGE_CONSTANT_ERROR["belMedian"]},
        belSigma: { value: state[eachKey].belSigma, error: RANGE_CONSTANT_ERROR["belSigma"]},
        sfSigma: { value: state[eachKey].sfSigma, error: RANGE_CONSTANT_ERROR["sfSigma"]}
      })
    })

    const isValid = this.validate(testCases);
    if (isValid) {
      const {selectedData} = this.props.rvrConfigReducer ;
      Object.keys(state).forEach((eachKey) => {
        selectedData[eachKey]['rangeCalculator'] = {
          ...selectedData[eachKey]['rangeCalculator'],
          txGain: state[eachKey]?.txGain,
          rxGain: state[eachKey]?.rxGain,
          desense: state[eachKey]?.desense,
          shadowFading: state[eachKey]?.shadowFading,
          frequency: state[eachKey]?.frequency,
          dutToDutVariation: state[eachKey]?.dutToDutVariation,  
          belMedian: state[eachKey]?.belMedian,  
          belSigma: state[eachKey]?.belSigma, 
          sfSigma: state[eachKey]?.sfSigma

        };
      })
      this.props.dispatch(setUserSelectDict(selectedData));
      this.props.onDismiss();
    }
  };

  validate = (testcases) => {
    let result = true;
    testcases.forEach((item,index) => {
      Object.keys(item).forEach((eachKey) => {
        if (!/^-?\d*\.?\d+$/i.test(item[eachKey].value)) {
          result = false;
          this.setState((prevState) =>({
            [index] : {
                ...prevState[index],
                [item[eachKey].error]: "Must input number",
            }
          }))
        }
      });
    }) 
    return result;
  };

  _onReset = () => {
    const {selectedData} =  this.props.rvrConfigReducer;
    Object.keys(selectedData).forEach((eachKey) => {
      let constants = findRangeConstants(selectedData[eachKey]?.technology ?? DEFAULT_TECHNOLOGY,selectedData[eachKey]?.frequency);
      this.setState({
        [eachKey] : {
            ...constants,
            txGainError: "",
            rxGainError: "",
            desenseError: "",
            shadowFadingError: "",
            frequencyError: "",
            dutToDutVariationError: "",
            belMedianError: "",
            belSigmaError: "",
            sfSigmaError: ""
        }
      })
    }) 
  };


  _cleanup = () => {
    const {selectedData} =  this.props.rvrConfigReducer
    Object.keys(selectedData).map((eachKey, index) => (
      this.setState((prevState) =>({
        [eachKey] : {
            ...prevState[eachKey],
            txGainError: "",
            rxGainError: "",
            desenseError: "",
            shadowFadingError: "",
            frequencyError: "",
            dutToDutVariationError: "",
            belMedianError: "",
            belSigmaError: "",
            sfSigmaError: ""
        }
      }))
    )) 
  };

  render() {
    const { showModal, onDismiss, onClose } = this.props;
    const { selectedData } = this.props.rvrConfigReducer;
    Object.keys(selectedData).forEach((eachKey) => {
      selectedData[eachKey]["title"] = findTitle(selectedData[eachKey])
    })
    return (
      <Modal
        visible={showModal}
        onDismiss={onDismiss}
        size="large"
        footer={
          <span className="awsui-util-f-r">
            <Button variant="link" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={this._onReset}>reset</Button>
            <Button variant="primary" onClick={this.handleSubmit}>
              Done
            </Button>
          </span>
        }
      >
    {
      Object.keys(selectedData).map((eachKey, index) => (
        <ExpandableSection variant="container" header={selectedData[eachKey]["title"]}>
          <FormField
          label={RANGE_CONSTANT["antTx"]["label"]}
          description={RANGE_CONSTANT["antTx"]["description"]}
          errorText={this.state[eachKey]?.txGainError}
          stretch={true}
        >
          <Input
            onChange={({ detail }) => {
              this.setState((prevState) => ({
                [eachKey] : {
                  ...prevState[eachKey],
                  txGain: detail.value,
                }
              }))
            }}
            value={this.state[eachKey]?.txGain.toString()}
          />
        </FormField>
        <br />
        <FormField
          label={RANGE_CONSTANT["antRx"]["label"]}
          description={RANGE_CONSTANT["antRx"]["description"]}
          errorText={this.state[eachKey]?.rxGainError}
          stretch={true}
        >
          <Input
            onChange={({ detail }) => {
              this.setState((prevState) => ({
                [eachKey] : {
                  ...prevState[eachKey],
                  rxGain: detail.value,
                }
              }))
            }}
            value={this.state[eachKey]?.rxGain.toString()}
          />
        </FormField>     
        {  selectedData[eachKey]['range']?.toLowerCase() === RANGE_TYPE_OUTDOOR ?
          <>
            <br />
            <FormField
              label={RANGE_CONSTANT["sf"]["label"]}
              description={RANGE_CONSTANT["sf"]["description"]}
              errorText={this.state[eachKey]?.sfSigmaError}
              stretch={true}
            >
              <Input
                onChange={({ detail }) => {
                  this.setState((prevState) => ({
                    [eachKey] : {
                      ...prevState[eachKey],
                      sfSigma: detail.value,
                    }
                  }))
                }}
                value={this.state[eachKey]?.sfSigma.toString()}
              />
            </FormField> <br />
            <FormField
              label={RANGE_CONSTANT["bel"]["label"]}
              description={RANGE_CONSTANT["bel"]["description"]}
              errorText={this.state[eachKey]?.belSigmaError}
              stretch={true}
            >
              <Input
                onChange={({ detail }) => {
                  this.setState((prevState) => ({
                    [eachKey] : {
                      ...prevState[eachKey],
                      belSigma: detail.value,
                    }
                  }))
                }}
                value={this.state[eachKey]?.belSigma.toString()}
              />
            </FormField> <br />
            <FormField
              label={RANGE_CONSTANT["dutVariation"]["label"]}
              description={RANGE_CONSTANT["dutVariation"]["description"]}
              errorText={this.state[eachKey]?.dutToDutVariationError}
              stretch={true}
            >
              <Input
                onChange={({ detail }) => {
                  this.setState((prevState) => ({
                    [eachKey] : {
                      ...prevState[eachKey],
                      dutToDutVariation: detail.value,
                    }
                  }))
                }}
                value={this.state[eachKey]?.dutToDutVariation.toString()}
              />
            </FormField> <br />
            <FormField
              label={RANGE_CONSTANT["belMedian"]["label"]}
              description={RANGE_CONSTANT["belMedian"]["description"]}
              errorText={this.state[eachKey]?.belMedianError}
              stretch={true}
            >
              <Input
                onChange={({ detail }) => {
                  this.setState((prevState) => ({
                    [eachKey] : {
                      ...prevState[eachKey],
                      belMedian: detail.value,
                    }
                  }))
                }}
                value={this.state[eachKey]?.belMedian.toString()}
              />
            </FormField> 
          </>
            : ""}
        {  selectedData[eachKey]['range']?.toLowerCase() === RANGE_TYPE_INDOOR ?
          <>
            <br />
            <FormField
              label={RANGE_CONSTANT["desense"]["label"]}
              description={RANGE_CONSTANT["desense"]["description"]}
              errorText={this.state[eachKey]?.desenseError}
              stretch={true}
            >
              <Input
                onChange={({ detail }) => {
                  this.setState((prevState) => ({
                    [eachKey] : {
                      ...prevState[eachKey],
                      desense: detail.value,
                    }
                  }))
                }}
                value={this.state[eachKey]?.desense.toString()}
              />
            </FormField>
            <br />
            <FormField
              label={RANGE_CONSTANT["shadowFading"]["label"]}
              description={RANGE_CONSTANT["shadowFading"]["description"]}
              errorText={this.state[eachKey]?.shadowFadingError}
              stretch={true}
            >
              <Input
                onChange={({ detail }) => {
                  this.setState((prevState) => ({
                    [eachKey] : {
                      ...prevState[eachKey],
                      shadowFading: detail.value,
                    }
                  }))
                }}
                value={this.state[eachKey]?.shadowFading.toString()}
              />
            </FormField>
          </>
            : ""
        }         
        <br />
        <FormField
          label={RANGE_CONSTANT["frequency"]["label"]}
          description={RANGE_CONSTANT["frequency"]["description"]}
          errorText={this.state[eachKey]?.frequencyError}
          stretch={true}
        >
          <Input
            onChange={({ detail }) => {
              this.setState((prevState) => ({
                [eachKey] : {
                  ...prevState[eachKey],
                  frequency: detail.value,
                }
              }))
            }}
            value={this.state[eachKey]?.frequency.toString()}
          />
        </FormField>
        </ExpandableSection>
        ))
    }  
        
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rvrConfigReducer: state.rvrConfigReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(RvRRangeModal);

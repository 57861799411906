/*
    wiki: https://wiki.labcollab.net/confluence/display/WDI/LensUpload

    File name format:

        Engineering:
            Power Table:  <:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_power-table.csv
            e.g.: aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_power-table.csv
 
            Band Edge:    <:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_band-edge.csv
            e.g.: aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_band-edge.csv
 
            Harmonics:    <:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_harmonics.csv
            e.g.: aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_harmonics.csv
 
            Active Scan:  <:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_active-scan.csv
            e.g.: aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_active-scan.csv
        
        Compliance:
            Power Table: <:category>_<:project>_<:build>_<:version>_compliance_powertable.xlsx
            e.g.: aucc_lidar_evt_1.0_compliance_powertable.xlsx

            Master plan(with Band Edge/Harmonics Limit): compliance_master_repository.xlsx

            Scan Limit: compliance_scan_limit.xlsx
*/

// key
export const FILE_TYPE = {
  ENG_POWER_TABLE: "engcom_power_table",
  ENG_BE: "engcom_band_edge",
  ENG_HAR: "engcom_harmonics",
  ENG_ACTIVE_SCAN: "engcom_active_scan",
  COM_POWER_Table: "compliance_power_table",
  COM_MASTER_PLAN: "compliance_master_repository",
  COM_SCAN_LIMIT: "compliance_scan_limit",
  ENG_NONSIG_BT: "eng_nonsig_bt",
  ENG_NONSIG_LITEPOINT_WIFI_BT_ZIGBEE: "eng_nonsig_litepoint_wifi_bt_zigbee",
  ENG_NONSIG_LITEPOINT_LORA: "eng_nonsig_litepoint_lora",
  ENG_NONSIG_LORA: "eng_nonsig_lora",
  ENG_NONSIG_WIFI_AX: "eng_nonsig_wifi_ax",
  ENG_NONSIG_WIFI_LEGACY: "eng_nonsig_wifi_legacy",
  BATTERY_MASTER_VIEW_TEMPLATE: "battery_master_view_template",
};

export const FILE_NAME_FORMAT = {
  [FILE_TYPE.ENG_POWER_TABLE]: {
    text: "Engineering Power Table",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_power-table.csv",
    filename: "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_power-table.csv",
  },
  [FILE_TYPE.ENG_BE]: {
    text: "Engineering Band Edge",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_band-edge.csv",
    filename: "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_band-edge.csv",
  },
  [FILE_TYPE.ENG_HAR]: {
    text: "Engineering Harmonics",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_harmonics.csv",
    filename: "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_harmonics.csv",
  },
  [FILE_TYPE.ENG_ACTIVE_SCAN]: {
    text: "Engineering Active Scan",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_active-scan.csv",
    filename: "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_active-scan.csv",
  },
  [FILE_TYPE.COM_POWER_Table]: {
    text: "Compliance Power Table",
    format:
      "<:category>_<:project>_<:build>_<:version>_compliance_powertable.xlsx",
    filename: "aucc_lidar_evt_1.0_compliance_powertable.xlsx",
  },
  [FILE_TYPE.COM_MASTER_PLAN]: {
    text: "Compliance Master Test Plan",
    format: "compliance_master_repository.xlsx",
    filename: "compliance_master_repository.xlsx",
  },
  [FILE_TYPE.COM_SCAN_LIMIT]: {
    text: "Compliance Scan Limit",
    format: "compliance_scan_limit.xlsx",
    filename: "compliance_scan_limit.xlsx",
  },
  desense: {
    text: "Desense",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_<:firmware>_desense.csv",
    filename: "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_firmware1_desense.csv",
  },
  rvr: {
    text: "RvR",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:dsn>_<:[testitem]>_<:channel>_<technology>_<:link>_<frequency>_rvr.csv",
    filename:
      "aucc_lidar_evt_1.0_P0B1QE0291220004_[WIFI_only]_Ch1_2G_Downlink_2412_rvr.csv",
  },
  battery: {
    text: "Battery",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_<:temperature>_<:template>_battery.csv",
    filename:
      "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_25c_ChargerVoltageSweep_battery.csv",
  },
  antenna: {
    text: "Antenna",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_antenna.xlsx",
    filename: "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_antenna.xlsx",
  },
  [FILE_TYPE.ENG_NONSIG_LITEPOINT_WIFI_BT_ZIGBEE]: {
    text: "Litepoint WiFi, Bluetooth, ZigBee",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_<:temperature>_<:voltage>_<:test-item>_non-sig-litepoint_<:optional_note>.csv",
    filename:
      "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_25C_3.3V_brx0_non-sig-litepoint_examplenote.csv",
  },
  [FILE_TYPE.ENG_NONSIG_LITEPOINT_LORA]: {
    text: "Litepoint LoRa",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_<:temperature>_<:voltage>_<:test-item>_non-sig-litepoint_<:optional_note>.txt",
    filename:
      "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_25C_3.3V_ltx0_non-sig-litepoint.txt",
  },
  [FILE_TYPE.ENG_NONSIG_BT]: {
    text: "Non-signaling BT",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_<:temperature>_<:voltage>_bt_non-sig.xlsx",
    filename:
      "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_25C_3.3V_bt_non-sig.xlsx",
  },
  [FILE_TYPE.ENG_NONSIG_LORA]: {
    text: "Non-signaling LoRa",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_<:temperature>_<:voltage>_lora-fsk_non-sig.xlsx",
    filename:
      "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_25C_3.3V_lora-fsk_non-sig.xlsx",
  },
  [FILE_TYPE.ENG_NONSIG_WIFI_AX]: {
    text: "Non-signaling WiFi AX",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_<:temperature>_<:voltage>_wifi-ax_non-sig.xlsx",
    filename:
      "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_25C_3.3V_wifi-ax_non-sig.xlsx",
  },
  [FILE_TYPE.ENG_NONSIG_WIFI_LEGACY]: {
    text: "Non-signaling WiFi Legacy",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_<:temperature>_<:voltage>_wifi-legacy_non-sig.xlsx",
    filename:
      "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_25C_3.3V_wifi-legacy_non-sig.xlsx",
  },
  [FILE_TYPE.BATTERY_MASTER_VIEW_TEMPLATE]: {
    text: "Battery master View Template",
    format: "battery_master_view_template.xlsx",
    filename: "battery_master_view_template.xlsx",
  }
};

export const UPLOAD_PAGE_SIZE = 1000;

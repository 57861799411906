import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
// directly use plotly
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import {
  Select,
  Multiselect,
  Input,
  Icon,
  Button,
} from "@amzn/awsui-components-react/polaris";
import {
  formatSelectedData,
  getAxisOptions,
  showAlert,
} from "../../rvr/rvr-base-chart/base-chart-helper";
import {
  BATTERY_DEFAULT_GRAPH_AXIS,
  BATTERY_TITLE
} from "../../../../config/constants";
import {
  setUserSelectDict
} from "../../../../redux/actions/battery-config-action";
import "../../rvr/rvr-base-chart/rvr-base-style.css";

interface StateProps {
  batteryConfigReducer: any;
}

// declare prop check
type Props = {
  filterObject: Object;
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  mainPlotWidth: window.innerWidth,
  xTitle: BATTERY_TITLE.X_AXIS, 
  yTitle: BATTERY_TITLE.Y_AXIS,
  y2Title: BATTERY_TITLE.Y2_AXIS,
  xSelect: BATTERY_DEFAULT_GRAPH_AXIS['x_axis'],
  ySelect: BATTERY_DEFAULT_GRAPH_AXIS['y_axis'],
  y2Select: BATTERY_DEFAULT_GRAPH_AXIS['y2_axis'],
  xMin: null,
  xMax: null,
  yMin: null,
  yMax: null,
  y2Min: null,
  y2Max: null,
});

class BatteryBaseChart extends Component<Props> {
  readonly state = initialState;
  
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
  }

  componentDidMount() {
    // handle resize for the visualization
    const rvrChartNode = this.plotRef.current;
    if (rvrChartNode) {
      this.setState({
        mainPlotWidth: rvrChartNode.clientWidth,
      });
    }
    const { selectedData } = this.props.batteryConfigReducer;
      this.setState({
        "xSelect": selectedData[0]['axis']['x_axis'],
        "ySelect": selectedData[0]['axis']['y_axis'],
        "y2Select": selectedData[0]['axis']['y2_axis']
      });
    // handle init new value
  }

  updateWindowDimensions = () => {
    // const node = this.dashboardRightRef.current;
    const rvrChartNode = this.plotRef.current;
    if (rvrChartNode) {
      this.setState({
        mainPlotWidth: rvrChartNode.clientWidth,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // Add event listener to window resize event
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  _resetInput = () => {
    const { selectedData , batteryViewTemplateList } = this.props.batteryConfigReducer;
    const foundObject = batteryViewTemplateList.find(obj => obj.filename_tag?.toLowerCase() === selectedData[0]['template']);
    if(foundObject){
      const updateSelected = { ...selectedData };
      updateSelected[0]['axis'] = {
        "x_axis": foundObject['x_axis'],
        "y_axis": foundObject['y_axis'].split(",").map(item => item.trim()),
        "y2_axis": foundObject['y2_axis'].split(",").map(item => item.trim())
      };
      this.props.dispatch(setUserSelectDict(updateSelected));
      this.setState({
        xSelect: updateSelected[0]['axis']['x_axis'],
        ySelect: updateSelected[0]['axis']['y_axis'],
        y2Select: updateSelected[0]['axis']['y2_axis'],
        xMin: null,
        xMax: null,
        yMin: null,
        yMax: null,
        y2Min: null,
        y2Max: null,
      });
    }
  };

  render() {
    const {
      xSelect,
      ySelect,
      y2Select,
      xTitle,
      yTitle,
      y2Title,
      xMin,
      yMin,
      y2Min,
      xMax,
      yMax,
      y2Max
    } = this.state;
    const { selectedData } = this.props.batteryConfigReducer;
    // chart layout
    const layout = {
      title: BATTERY_TITLE.GRAPH_TITLE,
      width: this.state.mainPlotWidth - 20 ,
      margin: {
        t: 25, //top margin
        b: 50, //bottom margin
      },
      yaxis2: {
        overlaying: 'y',
        side: 'right'
      },

      legend: {orientation: 'h', side: 'top'}
    };
    // if user input range & also double check
    if ([xMin, xMax].every((ele) => ele !== null)) {
      if ([xMin, xMax].every((ele) => !isNaN(Number(ele)))) {
        layout["xaxis"]["range"] = [xMin, xMax];
      }
    }

    if ([yMin, yMax].every((ele) => ele !== null)) {
      if ([yMin, yMax].every((ele) => !isNaN(Number(ele)))) {
        layout["yaxis"]["range"] = [yMin, yMax];
      }
    }

    if ([yMin, yMax].every((ele) => ele !== null)) {
      if ([yMin, yMax].every((ele) => !isNaN(Number(ele)))) {
        layout["yaxis2"]["range"] = [yMin, yMax];
      }
    }
    // chart data
    const formatData1 = formatSelectedData(selectedData, xSelect, ySelect);
    const formatData2 = formatSelectedData(selectedData, xSelect, y2Select,true);
    const formatData = [...formatData1,...formatData2]

    // user option for x and y
    const axisOptions = getAxisOptions(selectedData);

    // User chart setting, set x y:  title, select, min, max
    // Too long for each, so split x & y for easiler reading & faster search
    const userSettingX = (
      <div className={"axis-select-component"}>
        {/* X axis input */}
        <div className={"axis-dropdown-element"}>
          <div className={"axis-dropdown-label"}>X Axis Title:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  xTitle: detail.value
                });
              }}
              value={xTitle}
            />
          </div>
        </div>
        {/* X axis select */}
        <div className={"axis-dropdown-element"}>
          <div className={"axis-dropdown-label"}>X Axis:</div>
          <div className={"axis-dropdown-select"}>
            <Select
              id={"xSelect"}
              empty="No options"
              placeholder="x axis select"
              selectedLabel="Selected"
              options={axisOptions}
              selectedOption={xSelect ? { id: xSelect, label: xSelect } : null}
              onChange={({ detail: { selectedId } }) => {
                this.setState({
                  xSelect: selectedId,
                });
              }}
              filteringType="auto"
            ></Select>
          </div>
        </div>
        {/* xmin */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>X Min:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  xMin: detail.value,
                });
              }}
              value={xMin}
            />
          </div>
        </div>
        {/* xmax */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>X Max:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  xMax: detail.value,
                });
              }}
              value={xMax}
            />
          </div>
        </div>

        {showAlert(xMin, xMax) && (
          <div className={"axis-input-warnning"}>
            <Icon name="status-warning" size="normal" variant="error" />
            <span className="rvr-input-warnning-text">
              Need both Min&Max to filter
            </span>
          </div>
        )}
      </div>
    );
    const userSettingY = (
      <div className={"axis-select-component"}>
        {/* Y axis input */}
        <div className={"axis-dropdown-element"}>
          <div className={"axis-dropdown-label"}>Y Axis Title:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  yTitle: detail.value,
                });
              }}
              value={yTitle}
            />
          </div>
        </div>
        {/* Y axis select */}
        <div className={"axis-dropdown-element"}>
          <div className={"axis-dropdown-label"}>Y Axis:</div>
          <div className={"axis-dropdown-select"}>
            <Multiselect
              id={"ySelect"}
              empty="No options"
              placeholder="Y axis select"
              selectedLabel="Selected"
              options={axisOptions}
              selectedOptions={
                ySelect
                  ? ySelect.map((eachSelect) => {
                      return { id: eachSelect, label: eachSelect };
                    })
                  : []
              }
              onChange={({ detail: { selectedIds } }) => {
                this.setState({
                  ySelect: selectedIds,
                });
              }}
              filteringType="auto"
            ></Multiselect>
          </div>
        </div>
        {/* y min */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>Y Min:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  yMin: detail.value,
                });
              }}
              value={yMin}
            />
          </div>
        </div>
        {/* ymax */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>Y Max:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  yMax: detail.value,
                });
              }}
              value={yMax}
            />
          </div>
        </div>

        {showAlert(yMin, yMax) && (
          <div className={"axis-input-warnning"}>
            <Icon name="status-warning" size="normal" variant="error" />
            <span className="rvr-input-warnning-text">
              Need both Min&Max to filter
            </span>
          </div>
        )}
      </div>
    );
    const userSettingY2 = (
      <div className={"axis-select-component"}>
        {/* Y axis input */}
        <div className={"axis-dropdown-element"}>
          <div className={"axis-dropdown-label"}>Y2 Axis Title:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  y2Title: detail.value,
                });
              }}
              value={y2Title}
            />
          </div>
        </div>
        {/* Y axis select */}
        <div className={"axis-dropdown-element"}>
          <div className={"axis-dropdown-label"}>Y2 Axis:</div>
          <div className={"axis-dropdown-select"}>
            <Multiselect
              id={"y2Select"}
              empty="No options"
              placeholder="Y axis 2 select"
              selectedLabel="Selected"
              options={axisOptions}
              selectedOptions={
                y2Select
                  ? y2Select.map((eachSelect) => {
                      return { id: eachSelect, label: eachSelect };
                    })
                  : []
              }
              onChange={({ detail: { selectedIds } }) => {
                this.setState({
                  y2Select: selectedIds,
                });
              }}
              filteringType="auto"
            ></Multiselect>
          </div>
        </div>
        {/* y min */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>Y2 Min:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  y2Min: detail.value,
                });
              }}
              value={y2Min}
            />
          </div>
        </div>
        {/* ymax */}
        <div className={"axis-dropdown-element-range"}>
          <div className={"axis-dropdown-label"}>Y2 Max:</div>
          <div className={"axis-dropdown-input"}>
            <Input
              onChange={({ detail }) => {
                this.setState({
                  y2Max: detail.value,
                });
              }}
              value={y2Max}
            />
          </div>
        </div>

        {showAlert(y2Min, y2Max) && (
          <div className={"axis-input-warnning"}>
            <Icon name="status-warning" size="normal" variant="error" />
            <span className="rvr-input-warnning-text">
              Need both Min&Max to filter
            </span>
          </div>
        )}
      </div>
    );
    return (
      <div className={"rvr-base-chart"} ref={this.plotRef}>
        <div className="awsui-util-container rvr-chart-container">
          <div className={"rvr-y-title"}>{yTitle}</div>
          <div className={"rvr-plot"}>
            <Plot
              data={formatData ? formatData : []}
              layout={layout}
              config={{ displayModeBar: false }} // not showing mode bar
            />
          </div>
          <div className={"rvr-y2-title"}>{y2Title}</div>
          <div className={"rvr-x-title"}>{xTitle}</div>
          <div className="visual-reset-btn">
            <Button onClick={this._resetInput}>
              <Icon name="undo" size="normal" variant="normal" />
            </Button>
          </div>
        </div>
        {userSettingX}
        {userSettingY}
        {userSettingY2}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    batteryConfigReducer: state.batteryConfigReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(BatteryBaseChart);
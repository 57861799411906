class Endpoints {
  /* 
        define all you endpoints here
        each endpoint will be in form of function 
    */
  sampleEndpoint = (if_you_need_variable: string) =>
    `/this_is_an_example/${if_you_need_variable}`;

  /* ***************************************************************** */
  // metadata
  metadataEndpoint = () => "/metadata";

  // landing/dashboard table
  landingEndpint = () => "/landing";

  /* ***************************************************************** */
  // overview
  // !!! the build is basically build_version
  overviewStatus = (project, build, testCategory) =>
    `/projects/${project}/builds/${build}/testcategories/${testCategory}/overview`;

  // !!! the build is basically build_version
  filterList = (project, build, testCategory) =>
    `/projects/${project}/builds/${build}/testcategories/${testCategory}/overview/filterlist`;

  /* ***************************************************************** */
  /* Upload related */
  // file name check
  uploadFileNameCheck = () => "/upload/check";

  // generate pre-signed url
  uploadPreSignedURL = () => "/upload";

  // upload status
  uploadStatus = () => "/upload/status";

  // file templates presigned url
  fileTemplate = (filename) => `/file_template?fname=${filename}`;

  // upload auto trigger
  triggerAutoUpload = (initiative) =>
    `/upload/triggerauto?initiative=${initiative}`;

  /* ***************************************************************** */
  /* Visualization related */
  // get tcs test result data, desense also use this one
  tcsResultURL = (testCategory, tcsId) =>
    `/testcategories/${testCategory}/testcases/${tcsId}/data`;

  batteryViewTemplateListURL = () => `/battery/view/template/list`;

  // testcase status
  tcsStatus = (testcategory, dsn, config, testType, db_key) => {
    let endpoint = `/testcategories/${testcategory}/testcases/status?dsn=${dsn}&config=${config}&test_type=${testType}&db_key=${db_key}`;

    return endpoint;
  };

  // test case status
  // !!! the build is basically build_version
  testcaseStatus = (
    project,
    build,
    testCategory,
    testType,
    countryOrDevice,
    requestId: number | undefined = undefined,
    labId: number | undefined = undefined
  ) => {
    let endpoint = `/testcases?project=${project}&build=${build}&testcategory=${testCategory}&testtype=${testType}&countryordevice=${countryOrDevice}`;
    if (requestId) {
      endpoint = `${endpoint}&request_id=${requestId}`;
    }
    if (labId) {
      endpoint = `${endpoint}&lab_id=${labId}`;
    }
    // console.log(endpoint);
    return endpoint;
  };

  /* ***************************************************************** */
  /* Main category/project/build related */
  // get category list
  getCategoryListEndpoint = () => `/categories`;

  // create category
  createCategoryEndpoint = (category) => `/categories/${category}`;

  // get project list
  getProjectListEndpoint = (category) => `/categories/${category}/projects`;
  getProjectListWithoutCategoryEndpoint = () => `/projects`;

  // create project
  createProjectEndpoint = (project) => `/projects/${project}`;

  // create build
  createBuildEndpoint = (project, build) =>
    `/projects/${project}/builds/${build}`;

  // get build list
  getBuildList = (project) => `/projects/${project}/builds`;

  // get project {build: build_id} object once for all
  getProjectBuildInfo = () => "/project_build_info";

  // update build
  updateBuildList = (project, build) => `/projects/${project}/builds/${build}`;

  /* ***************************************************************** */
  // Report generation
  reportEndpoint = () => `/reports`;

  // Email verification
  emailVerificationEndpoint = () => `/email/verify`;

  // Report history
  reportHistoryEndpoint = () => `/reports/history`;

  /* ***************************************************************** */
  // Box Plot
  boxPlotEndpoint = () => `/boxplot`;

  /* ***************************************************************** */
  // User metrics
  metricEndpoint = () => `/log/metrics`;

  /* ***************************************************************** */
  /* Desense related */
  getDesenseDeviceListEndpoint = () => `/desense/config`;

  getDesenseTCSListEndpoint = () => `/desense/tcs`;

  // Power
  channelPowerEndpoint = (testcategory: string) =>
    `/testcategories/${testcategory}/power/channel`;

  customPowerEndpoint = (testcategory: string) =>
    `/testcategories/${testcategory}/power/custom`;

  /* ***************************************************************** */
  /* general testcase dropdown related DSN and config*/
  // /project/{project}/build/{build}/version/{version}/testcategory/{testcategory}/testcases
  getDropdownListEndpoint = (
    project: string,
    build: string,
    version: string,
    testcategory: string,
    requestId: number | undefined = undefined,
    labId: number | undefined = undefined
  ) => {
    let endpoint = `/project/${project}/build/${build}/version/${version}/testcategory/${testcategory}/testcases`;
    if (requestId) {
      endpoint = `${endpoint}?request_id=${requestId}`;
    }
    if (labId) {
      endpoint = `${endpoint}&lab_id=${labId}`;
    }
    return endpoint;
  };
}

const endpoints = new Endpoints();
export default endpoints;

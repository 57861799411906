import React, { Component } from "react";
import {
  ExpandableSection,
  Alert,
  ColumnLayout,
  Tabs,
} from "@amzn/awsui-components-react/polaris";
// constants
import { FILE_TYPE, FILE_NAME_FORMAT } from "../../../config/upload-config";
import constants, { TEST_CATEGORY } from "../../../config/constants";
import { capitalizeFirstLetter } from "../../../utils/general-utils";
import { downloadFileTemplate } from "./utils";
import styled from "styled-components";

// Engineering file types
const SHOW_ENG_TYPES = [
  FILE_TYPE.ENG_POWER_TABLE,
  FILE_TYPE.ENG_ACTIVE_SCAN,
  FILE_TYPE.ENG_BE,
  FILE_TYPE.ENG_HAR,
];
// Compliance file typess
const SHOW_COM_TYPES = [
  FILE_TYPE.COM_POWER_Table,
  FILE_TYPE.COM_MASTER_PLAN,
  FILE_TYPE.COM_SCAN_LIMIT,
];
const SHOW_BATTERY_MASTER_TYPES = [FILE_TYPE.BATTERY_MASTER_VIEW_TEMPLATE];

const SHOW_DES_TYPES = [TEST_CATEGORY.DESENSE];
const SHOW_RVR_TYPES = [TEST_CATEGORY.RVR];
const SHOW_BATTERY_TYPES = [TEST_CATEGORY.BATTERY];
const SHOW_ANTENNA_TYPES = [TEST_CATEGORY.ANTENNA];

const SHOW_NONSIG_TYPES = [
  FILE_TYPE.ENG_NONSIG_BT,
  FILE_TYPE.ENG_NONSIG_LORA,
  FILE_TYPE.ENG_NONSIG_WIFI_AX,
  FILE_TYPE.ENG_NONSIG_WIFI_LEGACY,
];

const SHOW_NONSIG_LITEPOINT_TYPES = [
  FILE_TYPE.ENG_NONSIG_LITEPOINT_WIFI_BT_ZIGBEE,
  FILE_TYPE.ENG_NONSIG_LITEPOINT_LORA,
];

type Props = {};
type State = { activeTab: string };

// Style a Span to look like a HyperLink
// Using <a href="#" onClick={foo}> is bad practice.
const StyleSpan = styled.span`
  color: #0073bb; // Light Blue
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

class UploadFileSupport extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { activeTab: TEST_CATEGORY.COMPLIANCE };
  }

  render() {
    const complianceInfoFiles = (
      <ColumnLayout columns={2} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          {/* Col_1: Engineering Files */}
          <div className="awsui-util-spacing-v-s">
            {/* column1 title */}
            <h3 className="awsui-util-p-n">From Engineering Compliance</h3>
            {/* column1 items */}
            {SHOW_ENG_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  <StyleSpan
                    onClick={() =>
                      downloadFileTemplate(FILE_NAME_FORMAT[type].filename)
                    }
                  >
                    {FILE_NAME_FORMAT[type].filename}
                  </StyleSpan>
                </div>
              </div>
            ))}
          </div>
          {/* Col_2: Compliance Files */}
          <div className="awsui-util-spacing-v-s">
            {/* column2 title */}
            <h3 className="awsui-util-p-n">From Compliance</h3>
            {/* column2 items */}
            {SHOW_COM_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  <StyleSpan
                    onClick={() =>
                      downloadFileTemplate(FILE_NAME_FORMAT[type].filename)
                    }
                  >
                    {FILE_NAME_FORMAT[type].filename}
                  </StyleSpan>
                </div>
              </div>
            ))}
          </div>{" "}
        </div>
      </ColumnLayout>
    );

    const desenseInfoFiles = (
      <ColumnLayout columns={1} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          {/* Col_1: Desense Files */}
          <div className="awsui-util-spacing-v-s">
            {/* column1 title */}
            <h3 className="awsui-util-p-n">From Engineering Desense</h3>
            {/* column1 items */}
            {SHOW_DES_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  <StyleSpan
                    onClick={() =>
                      downloadFileTemplate(FILE_NAME_FORMAT[type].filename)
                    }
                  >
                    {FILE_NAME_FORMAT[type].filename}
                  </StyleSpan>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ColumnLayout>
    );
    const rvrInfoFiles = (
      <ColumnLayout columns={1} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          {/* Col_1: RvR Files */}
          <div className="awsui-util-spacing-v-s">
            {/* column1 title */}
            <h3 className="awsui-util-p-n">From Engineering RvR</h3>
            {/* column1 items */}
            {SHOW_RVR_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                  <br/>
                  (Note: Ensure frequency is in Megahertz (MHz) or convert it before uploading.)<br/>
                </div>
                <div>
                  <b>{"Example: "}</b>
                  <StyleSpan
                    onClick={() =>
                      downloadFileTemplate(FILE_NAME_FORMAT[type].filename)
                    }
                  >
                    {FILE_NAME_FORMAT[type].filename}
                  </StyleSpan>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ColumnLayout>
    );
    const batteryInfoFiles = (
      <ColumnLayout columns={2} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          {/* Col_1: Battery Files */}
          <div className="awsui-util-spacing-v-s">
            {/* column1 title */}
            <h3 className="awsui-util-p-n">From Engineering Battery</h3>
            {/* column1 items */}
            {SHOW_BATTERY_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  <StyleSpan
                    onClick={() =>
                      downloadFileTemplate(FILE_NAME_FORMAT[type].filename)
                    }
                  >
                    {FILE_NAME_FORMAT[type].filename}
                  </StyleSpan>
                </div>
              </div>
            ))}
          </div>
          {/* Col_2: Battery Files */}
          <div className="awsui-util-spacing-v-s">
            {/* column2 title */}
            <h3 className="awsui-util-p-n">From Battery</h3>
            {/* column2 items */}
            {SHOW_BATTERY_MASTER_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  <StyleSpan
                    onClick={() =>
                      downloadFileTemplate(FILE_NAME_FORMAT[type].filename)
                    }
                  >
                    {FILE_NAME_FORMAT[type].filename}
                  </StyleSpan>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ColumnLayout>
    );
    const antennaInfoFiles = (
      <ColumnLayout columns={1} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          {/* Col_1: Antenna Files */}
          <div className="awsui-util-spacing-v-s">
            {/* column1 title */}
            <h3 className="awsui-util-p-n">From Engineering Antenna</h3>
            {/* column1 items */}
            {SHOW_ANTENNA_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  <StyleSpan
                    onClick={() =>
                      downloadFileTemplate(FILE_NAME_FORMAT[type].filename)
                    }
                  >
                    {FILE_NAME_FORMAT[type].filename}
                  </StyleSpan>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ColumnLayout>
    );

    const nonsigInfoFiles = (
      <ColumnLayout columns={2} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          {/* Col_1: Non-sig Files */}
          <div className="awsui-util-spacing-v-s">
            {/* column1 title */}
            <h3 className="awsui-util-p-n">Litepoint Non-signaling</h3>
            {/* column1 items */}
            {SHOW_NONSIG_LITEPOINT_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  <StyleSpan
                    onClick={() =>
                      downloadFileTemplate(FILE_NAME_FORMAT[type].filename)
                    }
                  >
                    {FILE_NAME_FORMAT[type].filename}
                  </StyleSpan>
                </div>
              </div>
            ))}
          </div>
          {/* Col_2: Non-sig Litepoint Files */}
          <div className="awsui-util-spacing-v-s">
            {/* column2 title */}
            <h3 className="awsui-util-p-n">
              NonLite Non-signaling
            </h3>
            {/* column2 items */}
            {SHOW_NONSIG_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  <StyleSpan
                    onClick={() =>
                      downloadFileTemplate(FILE_NAME_FORMAT[type].filename)
                    }
                  >
                    {FILE_NAME_FORMAT[type].filename}
                  </StyleSpan>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ColumnLayout>
    );

    const mappedTabInfo = [
      {
        label: capitalizeFirstLetter(TEST_CATEGORY.COMPLIANCE),
        id: TEST_CATEGORY.COMPLIANCE,
        content: <div>{complianceInfoFiles}</div>,
      },
      {
        label: capitalizeFirstLetter(TEST_CATEGORY.DESENSE),
        id: TEST_CATEGORY.DESENSE,
        content: <div>{desenseInfoFiles}</div>,
      },
      {
        label: constants.RVR,
        id: TEST_CATEGORY.RVR,
        content: <div>{rvrInfoFiles}</div>,
      },
      {
        label: constants.BATTERY,
        id: TEST_CATEGORY.BATTERY,
        content: <div>{batteryInfoFiles}</div>,
      },
      {
        label: capitalizeFirstLetter(TEST_CATEGORY.ANTENNA),
        id: TEST_CATEGORY.ANTENNA,
        content: <div>{antennaInfoFiles}</div>,
      },
      {
        label: capitalizeFirstLetter(TEST_CATEGORY.NON_SIG).replace("_", "-"),
        id: TEST_CATEGORY.NON_SIG,
        content: <div>{nonsigInfoFiles}</div>,
      },
    ];

    return (
      <ExpandableSection header="Which files are supported">
        <Alert>
          The files you are going to upload should follow below file name
          formats.
        </Alert>
        <br />
        <Tabs
          tabs={mappedTabInfo}
          activeTabId={this.state.activeTab}
          onChange={(e) => {
            this.setState({ activeTab: e.detail.activeTabId });
          }}
        />
      </ExpandableSection>
    );
  }
}

export default UploadFileSupport;

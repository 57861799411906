import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
// directly use plotly
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import { 
  getFormatRangeCalculateResult , 
  getFormatOutdoorRangeCalculateResult,
  findCommonAndUncommon,
  breakAfter100Chars } from "./range-calculator-utils";
import { Button, Icon, Input } from "@amzn/awsui-components-react/polaris";
import { 
  RANGE_TYPE_OUTDOOR, 
  DEFAULT_TECHNOLOGY, 
  RANGE_CONSTANT_TITLE,
  COGESTION_VALUES,
  FACTOR_COLLECTION_5G,
  FACTOR_COLLECTION_2G
} from "../../../../config/constants";
import "./range-chart-style.css";

interface StateProps {
  rvrConfigReducer: any;
}

// declare prop check
type Props = {
  filterObject: Object;
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  mainPlotWidth: window.innerWidth,
  xTitle: "range(m)",
  yTitle: "thoughput(Mbps)",
  xMin: null,
  xMax: null,
  yMin: null,
  yMax: null,
  factorCollection: COGESTION_VALUES,
  xScale: null,
  yScale: null
});

class RvRRangeChart extends Component<Props> {
  readonly state = initialState;
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
  }

  componentDidMount() {
    // handle resize for the visualization
    const rvrChartNode = this.plotRef.current;
    if (rvrChartNode) {
      this.setState({
        mainPlotWidth: rvrChartNode.clientWidth,
      });
    }
    // handle init new value
  }

  updateWindowDimensions = () => {
    // const node = this.dashboardRightRef.current;
    const rvrChartNode = this.plotRef.current;
    if (rvrChartNode) {
      this.setState({
        mainPlotWidth: rvrChartNode.clientWidth,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // Add event listener to window resize event
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  _resetInput = () => {
    this.setState({
      xMin: null,
      xMax: null,
      yMin: null,
      yMax: null,
      factorCollection: COGESTION_VALUES,
      xScale: null,
      yScale: null
    });
  };

  

  render() {
    const { selectedData } = this.props.rvrConfigReducer
    const ranges = [...new Set(Object.values(selectedData).map(tcs => tcs.range?.toLowerCase()))];
    const technologies = [...new Set(Object.values(selectedData).map(tcs => tcs.technology?.toLowerCase()))];

    const congestionValues = ranges.length === 1 && ranges.includes(RANGE_TYPE_OUTDOOR) && technologies.length === 1 ? 
                  technologies[0] === DEFAULT_TECHNOLOGY ? FACTOR_COLLECTION_5G : FACTOR_COLLECTION_2G :  [...FACTOR_COLLECTION_5G,...FACTOR_COLLECTION_2G] 


    const factorCollection = {}
    congestionValues.forEach((collection) => {
      factorCollection[collection] = this.state.factorCollection[collection]
    })
    const { commonValues, uncommonValues } = findCommonAndUncommon(selectedData);

    let title = breakAfter100Chars(RANGE_CONSTANT_TITLE + "("+commonValues.join("_")+")")
  
    const layout = {
      title: {
        text: title,
        font: {
          size: 15
        },
        x: 0.1,
      },
      width: this.state.mainPlotWidth - 20,
      margin: {
        t: 25, //top margin
        b: 30, //bottom margin
      },
      yaxis: { 
        title: this.state.yTitle ,
        showspikes: true
      },
      xaxis: {
        title: this.state.xTitle,
        rangemode: "tozero",
        showspikes: true,
        spikesnap: "data"
      },
      legend: {
        x: 1,
        xanchor: "right",
        y: 1,
      },
    };
    if(this.state.xScale){
      layout["xaxis"]["autotick"] = false
      layout["xaxis"]["dtick"] = this.state.xScale
    }
    if(this.state.yScale){
      layout["yaxis"]["autotick"] = false
      layout["yaxis"]["dtick"] = this.state.yScale
    }
    const config =  {
      displayModeBar: true,
      // scrollZoom: true,
      editable: true,
      toImageButtonOptions: {
        filename: title,
      }
    }
    let formattedData = [];

    Object.keys(selectedData).forEach((eachTC) => {
      let currentFormatDataArray = []
      let congestionValues = selectedData[eachTC]["range"]?.toLowerCase() === RANGE_TYPE_OUTDOOR ?
      selectedData[eachTC]["technology"]?.toLowerCase() === DEFAULT_TECHNOLOGY ? FACTOR_COLLECTION_5G : FACTOR_COLLECTION_2G 
        :  [...FACTOR_COLLECTION_5G,...FACTOR_COLLECTION_2G] 

      let factorCollection = {}
      congestionValues.forEach((collection) => {
        factorCollection[collection] = this.state.factorCollection[collection]
      })
      if (selectedData[eachTC]["range"]?.toLowerCase() === RANGE_TYPE_OUTDOOR) {
        currentFormatDataArray = getFormatOutdoorRangeCalculateResult(
          selectedData[eachTC],
          eachTC,
          uncommonValues,
          factorCollection
          );

      } else {
        currentFormatDataArray = getFormatRangeCalculateResult(
            selectedData[eachTC],
            eachTC,
            uncommonValues,
            factorCollection
          );
      }
      formattedData = formattedData.concat(currentFormatDataArray);
    })

    const congestionValuesInput = congestionValues.map((eachKey, index) => (
      <div className={"axis-dropdown-element-congestion"} key={index+1}>
        <div className={"axis-dropdown-label"}>{eachKey}: </div>
        <div className={"axis-dropdown-input"}>
          <Input
              onChange={({ detail }) => {
                this.setState((prevState) => ({
                  factorCollection : {
                    ...prevState['factorCollection'],
                    [eachKey]: detail.value,
                  }
                }))
              }}
              value={this.state.factorCollection[eachKey].toString()}
              inputMode="decimal"
              invalid={this.state.factorCollection[eachKey] < 0 || this.state.factorCollection[eachKey] > 100 }
              type="number"
            />
          </div>
      </div>
    ));
    const scaleMapping = [
      {
        "label": "X-Axis",
        "value": this.state.xScale,
        "key": "xScale"
      },
      {
        "label": "Y-Axis",
        "value": this.state.yScale,
        "key": "yScale"
      }
    ]

    const axisScaleInput = scaleMapping.map((axis, index) => (
      <div className={"axis-dropdown-element-congestion"} key={index+"-scale"}>
          <div className={"axis-dropdown-label"}>{axis["label"]}: </div>
          <div className={"axis-dropdown-input"}>
            <Input
                onChange={({ detail }) => {
                  this.setState({
                    [axis["key"]] : detail.value
                  })
                }}
                value={axis["value"] || "0" }
                inputMode="numeric"
                type="number"
              />
            </div>
      </div>
    ));

    return (
      <div className={"rvr-range-chart"} ref={this.plotRef}>
        <div className="awsui-util-container">
          <div>
            <Plot
              data={formattedData}
              layout={layout}
              config={config} // not showing mode bar
            />
          </div>
          <div className="visual-reset-btn">
            <Button onClick={this._resetInput}>
              <Icon name="undo" size="normal" variant="normal" />
            </Button>
          </div>
        </div>
        <div className={"axis-select-component"}>
            <div className={"axis-dropdown-element-congestion"} key={0}>
              <div className={"axis-dropdown-label"}>Congestion Values in % :  </div>
            </div>
            {congestionValuesInput}
        </div>
        <div className={"axis-select-component"}>
            <div className={"axis-dropdown-element-congestion"} key={"0-scale"}>
              <div className={"axis-dropdown-label"}>Change scale of axis:  </div>
            </div>
            {axisScaleInput}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rvrConfigReducer: state.rvrConfigReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(RvRRangeChart);
